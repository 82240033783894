import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./App.css";
// import ReactMarkdown from "react-markdown";
// import AppMarkdown from "./body.md";
import ScrollToTop from "./Utilities/scroll-to-top.js";
// import leadmaps from './lead-maps.js';
// import Source from "./components/reader/insets/Source.js";
import Heady from "./Utilities/head.js";
import NC from "./components/explorer/nc.js";
import Landing from "./components/reader/Landing";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = { terms: null };
  }

  // componentWillMount() {
  //   fetch(AppMarkdown)
  //     .then((response) => response.text())
  //     .then((text) => {
  //       this.setState({ terms: text });
  //     });
  // }

  render() {
    // const renderers = {
    //   code: ({ language, value }) => {
    //     if (language === "Lead-maps") {
    //       return <Lead-maps text={value} />;
    //     }

    //     if (language === "Source") {
    //       return <Source text={value} />;
    //     }

    //     const className = language && `language-${language}`;
    //     const code = React.createElement(
    //       "code",
    //       className ? { className: className } : null,
    //       value
    //     );
    //     return React.createElement("pre", {}, code);
    //   },
    // };

    return (
      <div>
        <Heady />

        <Router>
          <ScrollToTop>
            <Switch>

              <Route path="/nc">
                <NC />
              </Route>

              <Route path="/">
                <Landing />
              </Route>

            </Switch>
          </ScrollToTop>
        </Router>
      </div>
    );
  }
}

export default App;
