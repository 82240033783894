import React, { Component } from 'react';

class Header extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

// copy: "#" + ("000000" + Math.random().toString(16).slice(2, 8).toUpperCase()).slice(-6),

  toggleImage = () => {
    this.setState(state => ({
      sun: !state.sun,
    }),
    )
  } 

  componentDidMount() {
    const hours = new Date().getHours();
    console.log(hours)

    if (7 < hours &&  19 > hours ) {
      this.setState({ sun: true })
    } else {
      this.setState({ sun: false })
    }
  }
      
  eyeImage = () => 
    this.state.sun ? "sun" : "night"
  

  render() {
    
     return (
        <>
          {this.state.sun ? <style>{`:root {
              --bg: rgb(224, 224, 224);
              --copy: rgb(12, 12, 12);
              }
            `}</style> : null }
        <div className="heady">
          <p className="rolly">
              <span className="headright">
                <p onClick={this.toggleImage}> {this.eyeImage()} </p>
              </span>
          </p>
      </div>
      </>
      )}
}

export default Header;