import React, { Component } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./Landing.css";
import ReactMarkdown from "react-markdown";
import AppMarkdown from "./body.md";
// import ScrollToTop from './Utilities/scroll-to-top.js';
// import leadmaps from './lead-maps.js';
import Source from "./insets/Source";

class Landing extends Component {
  constructor(props) {
    super(props);

    this.state = { terms: null };
  }

  componentWillMount() {
    fetch(AppMarkdown)
      .then((response) => response.text())
      .then((text) => {
        this.setState({ terms: text });
      });
  }

  render() {
    const renderers = {
      code: ({ language, value }) => {
        if (language === "Lead-maps") {
          return <Lead-maps text={value} />;
        }

        if (language === "Source") {
          return <Source text={value} />;
        }

        const className = language && `language-${language}`;
        const code = React.createElement(
          "code",
          className ? { className: className } : null,
          value
        );
        return React.createElement("pre", {}, code);
      },
    };

    return (
              <div>
                <div className="big">
                  <h1>Where is the Public Space in Los Angeles?</h1>
                  <img src="media/skyline.jpg"></img>
                  </div>
                  
                  

                  <div className="copy">

                    <Link className="ncbutton" to="/nc/">
                      Neighborhood Data
                      </Link>
                    
                  <ReactMarkdown
                    renderers={renderers}
                    source={this.state.terms}
                  />
                </div>
              </div>
           
    );
  }
}

export default Landing;
