import React, {
  Component,
  useLayoutEffect,
  useRef,
  useEffect,
  useState,
} from "react";
import { Pie } from "react-chartjs-2";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import mapboxgl from "mapbox-gl";
import "./nc.css";
import streets from "../../data/merged_roads.geojson";
import Geodata from "../../data/Public-Space-Data";
import NC_Map from "./SelectNC/nc_map.js";
import Nclist from "./SelectNC/ncList.js";
import CityMap from "./Citymap/citymap";
import SelectNC from "./SelectNC/SelectNC";
import Ncbounder from "../../data/Enriched_neighborhood_councils";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  render() {
    console.log("nc built");
    // find current url path, connect data

    const locs = this.props.location.pathname;

    if (locs.length > 4) {
      const loc = this.props.location.pathname.split("/");
      const current_location = loc[loc.length - 1];
      console.log(current_location);

      const ncArray = Ncbounder.features;

      console.log(ncArray);

      const obj = ncArray.find((o, i) => {
        return o?.properties?.Link === current_location;
      });

      return (
        <div className="nc">
          <div className="nc-select">
            <NC_Map currentnc={obj.properties["Name"]} />
            <SelectNC obj={obj} ncArray={ncArray} />
          </div>
          <Nclist Geodata={Geodata} />
        </div>
      );
    } else {
      return (
        <div className="nc">
          <CityMap />
          <Nclist Geodata={Geodata} />
        </div>
      );
    }
  }
}

export default withRouter(Header);
