import React, { Component } from 'react';

class Source extends Component {

  constructor(props) {
    super(props)

    this.state = { terms: null }
  }
    

  render() {
      var nolink = (this.props.text).replace(/(?:https?|ftp):\/\/[\n\S]+/g, '')
      var justlink = (this.props.text).match(/(https?:\/\/[^ ]*)/).shift()
    
        
        return (
          <div class="source">
           <a target="_blank" href= {justlink}>
                {nolink}
            </a>
        </div>
      
  );
  }

}


export default Source;