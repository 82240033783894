import React, { Component } from "react";

const Wafflechart = ({ Park, Road, Walk }) => {
  const tot = Number(Park) + Number(Road) + Number(Walk);
  const walkwidth = (Walk / tot) * 100;
  const parkwidth = (Park / tot) * 100;
  const roadwidth = (Road / tot) * 100;

  return (
    <div className="waffle">
      <div className="wafflekey">
        <th> Public Space Distribution:</th>
        <div className="parkedkey">
          <p className="parkkey">{Park} Acres of Park 🌳</p>
          {/* <p className="shedkey">🌳 Park 10 min walkshed (BETA)</p> */}
        </div>
        <p className="sidekey">{Walk} Acres of Sidewalk 🚶🏽</p>
        <p className="roadkey">{Road} Acres of Road 🚗</p>
      </div>
      <div className="wafflechart">
        <div
          style={{
            background: "var(--park)",
            width: "calc(" + [parkwidth] + "% - 3px)",
          }}
        ></div>
        <div
          style={{
            background: "var(--walk)",
            width: "calc(" + [walkwidth] + "% - 3px)",
          }}
        ></div>
        <div
          style={{
            background: "var(--road)",
            width: "calc(" + [roadwidth] + "% - 3px)",
          }}
        ></div>
      </div>
    </div>
  );
};

export default Wafflechart;
