import React, { Component, useRef, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
// import "../../node_modules/mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import ncbounder from "../../../data/Enriched_neighborhood_councils.json";
import bbox from "@turf/bbox";
import Optionsfield from "./citymap_options";
import Legend from "./citymap_legend";

// mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken =
  "pk.eyJ1IjoiamF3c2h2IiwiYSI6ImNrNnZmYncwdjAxYjAzcG16ZG1zaXY4NmoifQ.o6Dleq9Ibuv2JSXw9JDZcw";

const CityMap = () => {
  console.log("changemap");
  const options = [
    {
      name: "Park per Capita",
      description:
        "Acre(s) of park per 1,000 people. The Median large city in the US has 6.8 acres per 1,000 people.",
      property: "Acre_1k",
      stops: [
        [0, "#eb6314"],
        [1, "#f2a641"],
        [2, "#f0e087"],
        [10, "#cbf087"],
        [40, "#5cd043"],
      ],
      after: " acres",
    },
    {
      name: "Park Need",
      description:
        "Acre(s) of new park needed to meet 1 Acre of park per 1,000 people",
      property: "Needed_Park",
      stops: [
        [0, "#5cd043"],
        [5, "#f0e087"],
        [10, "#ffc973"],
        [40, "#faa35c"],
        [70, "#eb6314"],
      ],
      after: " acres",
    },
    {
      name: "Median Income",
      description: "Estimated Area Median Income per Census Data",
      property: "MEDHINC_CY",
      stops: [
        [30000, "#eb6314"],
        [50000, "#f2a641"],
        [70000, "#cbf087"],
        [90000, "#97f087"],
        [110000, "#5cd043"],
      ],
      before: "$",
    },
  ];

  const mapContainerRef = useRef(null);
  const [active, setActive] = useState(options[0]);
  const [map, setMap] = useState(null);

  // Initialize map when component mounts
  useEffect(() => {
    let map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/jawshv/ckkaf3wzh0fkf17muxd04ui0l",
      // "mapbox://styles/jawshv/ckjixrr3103cq19r00i81zsw1",
      center: [-118.4, 34],
      zoom: 9,
      maxZoom: 11,
      minZoom: 9,
    });

    map.on("load", () => {
      map.addSource("ncbounder", {
        type: "geojson",
        data: ncbounder,
      });

      map.addLayer({
        id: "ncbound",
        type: "fill",
        source: "ncbounder",
        paint: {
          "fill-outline-color": "#ffffff",
        },
      });

      map.setPaintProperty("ncbound", "fill-color", {
        property: active.property,
        stops: active.stops,
      });

      // add tooltip when users mouse move over a point
      map.on("click", "ncbound", function (e) {
        const linker = e.features[0].properties.Link;
        const description =
          `<b>${e.features[0].properties.Name}</b><br/>
          <table>
          <tr>
          <td class="desc">Park:</td>
          <td>${e.features[0].properties.Park_Acres.toFixed(1)} acres</td>
          </tr>
          <tr>
          <td class="desc">Road:</td>
          <td>${e.features[0].properties.Road_Acres.toFixed(1)} acres</td>
          </tr>
          <tr>
          <td class="desc">Sidewalk:</td>
          <td>${e.features[0].properties.Sidewalk_Acres.toFixed(1)} acres</td>
          </tr>
          <tr>
          <td class="desc">Park per 1k:</td>
          <td>${e.features[0].properties.Acre_1k.toFixed(2)} acres</td>
          </tr>
          </table>
          <a href="${e.features[0].properties.Link}">Explore NC</a>`;

        new mapboxgl.Popup({
          closeButton: false,
        })
          .setLngLat(e.lngLat)
          .setHTML(description)
          .addTo(map);
      });

      // Change the cursor to a pointer when the mouse is over the places layer.
      map.on("mouseenter", "ncbound", function () {
        map.getCanvas().style.cursor = "pointer";
      });

      // Change it back to a pointer when it leaves.
      map.on("mouseleave", "ncbound", function () {
        map.getCanvas().style.cursor = "";
      });

      // Add navigation control (the +/- zoom buttons)
      map.addControl(new mapboxgl.NavigationControl(), "top-right");
      setMap(map);
    });

    // Clean up on unmount
    return () => map.remove();
  }, []);

  useEffect(() => {
    paint();
  }, [active]);

  const paint = () => {
    if (map) {
      map.setPaintProperty("ncbound", "fill-color", {
        property: active.property,
        stops: active.stops,
      });
    }
  };

  const changeState = (i) => {
    setActive(options[i]);
    map.setPaintProperty("ncbound", "fill-color", {
      property: active.property,
      stops: active.stops,
    });
  };

  return (
    <div className="city-map">
      <div className="home-bttn"><a href="/">Home</a></div>
      <div className="city-map-container" ref={mapContainerRef} />
      <Optionsfield
        options={options}
        property={active.property}
        changeState={changeState}
      />
      <Legend active={active} stops={active.stops} />
    </div>
  );
};

export default CityMap;
