import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  withRouter,
} from "react-router-dom";
import Tooltip from "./tooltip";
import Wafflechart from "./waffle.js"

const SelectNC = ({ obj, ncArray }) => {
  const [Allnc] = useState(ncArray);

  // chosen variables to rank

  const chain = [
    "Park_Acres",
    "Sidewalk_Acres",
    "Fixed_Road_Acres",
    "TOTPOP_CY",
    "CHILD_CY",
    "MEDHINC_CY",
    "DIVINDX_CY",
    "ACSHHBPOV",
    "Area-Acres",
    "Acre_1k",
    "New_Percent_Road",
    "New_Percent_Park",
    "Perc_Walkshed",
    "Needed_Park",
  ];

  // function to build ranks

  const ranked = [];

  chain.forEach((i) => {
    const e = ncArray.map(function (n) {
      return n?.properties?.[i];
    });
    e.sort(function (a, b) {
      return a - b;
    });
    let newranked = (e.indexOf(obj?.properties?.[i])+1) + "/" + e.length;
    ranked.push(newranked);
  });

  console.log(ranked);

  const Name = obj.properties["Name"];
  const Park = obj.properties["Park_Acres"].toFixed(1);
  const Parkrank = ranked[0];
  const Walk = obj.properties["Sidewalk_Acres"].toFixed(1);
  const Road = obj.properties["Fixed_Road_Acres"].toFixed(1);
  const Pop = obj.properties["TOTPOP_CY"].toFixed(0);
  const Poprank = ranked[3];
  const Child = obj.properties["CHILD_CY"].toFixed(0);
  const Childrank = ranked[4];
  const Income = obj.properties["MEDHINC_CY"].toFixed(0);
  const Incomerank = ranked[5];
  const Diversity = obj.properties["DIVINDX_CY"].toFixed(0);
  const Divrank = ranked[6];
  const Poverty = obj.properties["ACSHHBPOV"].toFixed(0);
  const Povrank = ranked[7];
  const Area = obj.properties["Area-Acres"].toFixed(0);
  const Arearank = ranked[8];
  const cap1k = obj.properties["Acre_1k"].toFixed(2);
  const cap1kRank = ranked[9];
  const PercRoad = obj.properties["New_Percent_Road"];
  const PRoadrank = ranked[10];
  const PercPark = obj.properties["New_Percent_Park"];
  const PParkrank = ranked[11];
  const WalkshedPerc = (obj.properties["Perc_Walkshed"]*100).toFixed(1);
  const WalkshedRank = ranked[12];
  const Park_Need = obj.properties["Needed_Park"].toFixed(1);
  const Park_NeedRank = ranked[13];

  const data = {
    labels: ["Streets", "Parks", "Sidewalks"],

    datasets: [
      {
        data: [Road, Park, Walk],
        backgroundColor: ["#c16270", "#008000", "#FFCE56"],
      },
    ],
  };

  const options = {
    elements: {
      arc: {
        borderWidth: 0.5,
      },
    },
    tooltips: {
      callbacks: {
        afterLabel: (item) => `${item.yLabel} Acres`,
      },
    },
  };

  return (
    <div className="nc-select-info">
      <Link to="/">Home</Link> / 
      <Link to="/nc/"> City Map</Link>
      <h2>{Name}</h2>
      <Wafflechart Park={Park} Walk={Walk} Road={Road} />
      <table>
        <tr>
          <th>NC Statistics:</th>
          <th></th>
          <th style={{ color: "grey", fontWeight: "300", fontSize: ".8em"}} >Rank</th>
        </tr>

        <tr>
          <td> Park space per 1K people: ⓘ
            <div className="tooltip">The Median large city in the US has 6.8 acres of park per 1,000 people.</div>
          </td>
          <td className="data">
            {cap1k} Acres
            </td>
          <td className="rank"> {cap1kRank}</td>
        </tr>
        <tr>
          <td>Minimum New Park Need: ⓘ
            <div className="tooltip">How many Acres of park is needed for the neighborhood council to have 1 acre per 1k people.</div>
          </td>
          <td className="data">
            {Park_Need} Acres          </td>
          <td className="rank"> {Park_NeedRank}</td>
        </tr>
        <tr>
          <td>Road Coverage:</td>
          <td className="data">{(PercRoad).toFixed(1)}%</td>
            <td className="rank">{PRoadrank}</td>
        </tr>
        {/* <tr>
          <td>Est. 10 Min Walkshed Coverage:</td>
          <td className="data">{(WalkshedPerc*1).toLocaleString("en")}%</td>
          <td className="rank"> {WalkshedRank}</td>
        </tr> */}
        <tr>
          <td>Park Coverage:</td>
          <td className="data">
            {(PercPark).toFixed(1)}%</td>
            <td className="rank"> {PParkrank}</td>
        </tr>
        <tr>
          <td> Median Income:</td>
          <td className="data">
            ${(Income * 1).toLocaleString("en")}          </td>
            <td className="rank"> {Incomerank}</td>
        </tr>
        <tr>
          <td> Population:</td>
          <td className="data">
            {(Pop * 1).toLocaleString("en")}</td>
            <td className="rank"> {Poprank}</td>
        </tr>
        <tr>
          <td>Below poverty line:</td>
          <td className="data">
            {(Poverty * 1).toLocaleString("en")}          </td>
            <td className="rank"> {Povrank}</td>
        </tr>
        <tr>
          <td>Below Age 18:</td>
          <td className="data">{(Child * 1).toLocaleString("en")}</td>
            <td className="rank"> {Childrank}</td>
        </tr>
        <tr>
          <td> Diversity Index:</td>
          <td className="data">
            {Diversity}          </td>
            <td className="rank"> {Divrank}</td>
        </tr> 
        <tr>
          <td>NC Area:</td>
          <td className="data">{(Area * 1).toLocaleString("en")} Acres</td>
          <td className="rank"> {Arearank}</td>
        </tr>
      </table>
    </div>
  );
};

export default SelectNC;
